import {get,put} from "@/api/axios";

export const list = params => get('/web/honey/withdraw/list', params);

// /web/honey/withdraw/examine put
export const examine = params => put('/web/honey/withdraw/examine', params);

// /web/honey/withdraw/batch/examine put
export const batchExamine = params => put('/web/honey/withdraw/batch/examine', params);

// /web/honey/withdraw/payment/{id} get
export const payment = params => get('/web/honey/withdraw/payment/' + params.id, '');

// /web/honey/withdraw/batch/payment put
export const batchPayment = params => put('/web/honey/withdraw/batch/payment', params);
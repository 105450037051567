<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
        @selection-change="selectionChange"
    >
      <template #header>
        <div class="flex align-items marginBottom10 flex-wrap">
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-help" @click="handleAllToExamine">批量审核</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-money" @click="handleAllPayment">批量打款</el-button>
          </div>
          <el-input size="medium" v-model="searchData.name" :maxlength="100" clearable placeholder="用户姓名" class="marginRight10 width200 marginBottom10"/>
          <el-input size="medium" v-model="searchData.phone" :maxlength="100" clearable placeholder="用户手机号" class="marginRight10 width200 marginBottom10"/>
          <div class="marginRight10 width200 marginBottom10">
            <el-select v-model="searchData.state" clearable>
              <el-option value="" label="审核状态" />
              <el-option :value="1" label="待审核" />
              <el-option :value="2" label="审核通过" />
              <el-option :value="3" label="审核驳回" />
            </el-select>
          </div>
          <div class="marginRight10 width200 marginBottom10">
            <el-select v-model="searchData.payState" clearable>
              <el-option value="" label="打款状态" />
              <el-option :value="1" label="已打款" />
              <el-option :value="2" label="未打款" />
            </el-select>
          </div>
          <div class="marginBottom10 marginRight10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginBottom10 marginRight10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginBottom10 marginRight10">
            <el-button size="medium" style="background: #c280ff;color: #ffffff;border-color: #c280ff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium"
                       icon="el-icon-upload2"
                       style="color: #ffffff;background: #f59a23;border-color: #f59a23"
                       @click="handleImport">导入更新打款状态</el-button>
          </div>
<!--          <div class="marginBottom10 marginRight10">-->
<!--            <el-upload-->
<!--                :headers="headers"-->
<!--                :action="action"-->
<!--                :show-file-list="false"-->
<!--                :on-success="handleAvatarSuccess"-->
<!--                :before-upload="beforeUpload"-->
<!--                multiple-->
<!--                type="primary"-->
<!--                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"-->
<!--                :limit="3"-->
<!--                :file-list="fileList"-->
<!--            >-->
<!--              <el-button size="medium" style="background: #f59a23;color: #ffffff;border-color: #f59a23" icon="el-icon-upload2">导入更新打款状态</el-button>-->
<!--            </el-upload>-->
<!--          </div>-->


        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" v-if="row.state === 1" icon="el-icon-help" @click="handleToExamine(row)">审核</el-button>
        <el-button type="text" size="mini" v-if="row.state === 2 && row.payState === 2" icon="el-icon-money" @click="handlePayment(row)">打款</el-button>
      </template>
    </avue-crud>

    <el-dialog
      title="审核"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="submit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
        :title="formPayment.dialogPaymentType ? '批量打款' : '打款'"
        v-if="dialogVisiblePayment"
        :visible.sync="dialogVisiblePayment"
        width="20%"
        top="20%"
        :before-close="handleClosePayment">
      <div class="flex align-items justify-content-center marginBottom30">
        <div>确认已线下打款？</div>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button @click="handleClosePayment">取消</el-button>
        <el-button type="primary" @click="handlePaymentSubmit">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="批量审核"
        v-if="dialogVisibleToExamineAll"
        :visible.sync="dialogVisibleToExamineAll"
        width="30%"
        :before-close="handleCloseToExamineAll">
      <avue-form ref="formToExamineAll"
                 v-model="formToExamineAll"
                 :option="formOptionToExamineAll"
                 @submit="submitToExamineAll">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleCloseToExamineAll">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <Import v-if="importShow"
            :show="importShow"
            :action="importAction"
            :downloadLink="importDownloadLink"
            :problemLink="importProblem"
            @close="importClose"/>
  </div>
</template>

<script>
import {batchExamine, batchPayment, examine, list, payment} from '@/api/honey/withdraw'
import  Import from '@/view/house/components/import'
import qs from "qs";
export default {
  name: "index",
  components: {
    Import
  },
  data(){
    return{
      // 导入
      fileList: [],
      headers: {
        Authorization: this.$store.state.user.Token
      },
      action: '/api/web/honey/withdraw/payment/import',

      searchData: {
        name: '',
        payState: '',
        phone: '',
        state: '',
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option:{
        title:'提现审核',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: true, // 显示多选框
        selectable:(row)=>{
          return row.payState === 2;
        },
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '提现ID',
            prop: 'id',
            width: 250,
          },
          {
            label: '提交时间',
            prop: 'createTime',
            width: 150,
          },
          {
            label: '用户昵称',
            prop: 'nickname',
            width: 150,
          },
          {
            label: '用户手机号',
            prop: 'phone',
            width: 150,
          },
          {
            label: '用户姓名',
            prop: 'username',
            width: 150,
          },
          {
            label: '提现蜜豆积分',
            prop: 'number',
            width: 150,
          },
          {
            label: '预计到账（含税）',
            prop: 'estimatedArrival',
            width: 150,
          },
          {
            label: '代缴税',
            prop: 'takePayTax',
            width: 150,
          },
          {
            label: '实际到账金额',
            prop: 'actualArrival',
            width: 150,
          },
          {
            label: '银行卡户名',
            prop: 'bankAccountName',
            width: 200,
          },
          {
            label: '银行名称',
            prop: 'bankDeposit',
            width: 200,
          },
          {
            label: '支行名称',
            prop: 'bankSubbranch',
            width: 200,
          },
          {
            label: '银行卡号',
            prop: 'bankNumber',
            width: 200,
          },
          {
            label: '审核状态',
            prop: 'state',
            formatter: (row, column, cellValue) => {
              return cellValue === 1 ? '待审核' : cellValue === 2 ? '审核通过' : cellValue === 3 ? '审核驳回' : ''
            },
            width: 150,
          },
          {
            label: '审核时间',
            prop: 'examineTime',
            width: 150,
          },
          {
            label: '审核说明',
            prop: 'examineDesc',
            width: 200,
          },
          {
            label: '打款状态',
            prop: 'payState',
            formatter: (row, column, cellValue) => {
              return cellValue === 1 ? '已打款' : cellValue === 2 ? '未打款' : ''
            },
            width: 150,
          },
          {
            label: '打款时间',
            prop: 'payTime',
            width: 150,
          }
        ]
      },
      multipleSelection: [],

      dialogVisible: false,
      form: {
        id: '',
        integral: 0,
        estimatedArrival: 0,
        state: 2,
        takePayTax: 0,
        actualArrival: 0,
        examineDesc: '',
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: '提现蜜豆积分',
            prop: 'integral',
            span: 24,
            disabled: true,
          },
          {
            label: '预计到账（含税）',
            prop: 'estimatedArrival',
            type: 'number',
            maxRows: 999999,
            minRows: 0,
            precision: 2,
            span: 24,
            rules: [{
              required: true,
              message: "请输入预计到账（含税）",
              trigger: "blur"
            }],
            display: true
          },
          {
            label: '审核',
            prop: 'state',
            type: 'radio',
            span: 24,
            dicData: [
              {
                label: '通过',
                value: 2
              },
              {
                label: '驳回',
                value: 3
              }
            ],
            rules: [{
              required: true,
              message: "请选择是否通过审核",
              trigger: "blur"
            }],
            change: (e)=>{
              const estimatedArrival = this.findObject(this.formOption.column,'estimatedArrival')
              const examineDesc = this.findObject(this.formOption.column,'examineDesc')
              estimatedArrival.display = e.value === 2
              examineDesc.rules[0].required = e.value === 3
              e.value === 2 && this.$refs.form.clearValidate(`examineDesc`);
            }
          },
          {
            label: '代缴税',
            prop: 'takePayTax',
            type: 'number',
            maxRows: 999999,
            minRows: 0,
            precision: 2,
            span: 24,
            rules: [{
              required: true,
              message: "请输入代缴税",
              trigger: "blur"
            }],
            onChange: (value) => {
              console.log(222,value)
            },
            display: true
          },
          {
            label: '实际到账金额',
            prop: 'actualArrival',
            type: 'number',
            precision: 2,
            span: 24,
            display: true,
            disabled: true,
          },
          {
            label: '审核说明',
            prop: 'examineDesc',
            maxlength: 30,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: false,
              message: "请填写审核说明",
              trigger: "blur"
            }]
          }
        ]
      },

      dialogVisiblePayment: false,
      formPayment: {
        id: '',
        dialogPaymentType: false
      },

      dialogVisibleToExamineAll: false,
      formToExamineAll: {
        state: 2,
        examineDesc: ''
      },
      formOptionToExamineAll: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: '审核',
            prop: 'state',
            type: 'radio',
            span: 24,
            dicData: [
              {
                label: '通过',
                value: 2
              },
              {
                label: '驳回',
                value: 3
              }
            ],
            rules: [{
              required: true,
              message: "请选择是否通过审核",
              trigger: "blur"
            }],
            change: (e)=>{
              const examineDesc = this.findObject(this.formOptionToExamineAll.column,'examineDesc')
              examineDesc.rules[0].required = e.value === 3
              e.value === 2 && this.$refs.formToExamineAll.clearValidate(`examineDesc`);
            }
          },
          {
            label: '审核说明',
            prop: 'examineDesc',
            maxlength: 30,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: false,
              message: "请填写审核说明",
              trigger: "blur"
            }]
          }
        ]
      },

      importShow: false,
      importAction: '/api/web/honey/withdraw/template/import',
      importDownloadLink: '/api/web/honey/withdraw/template/export',
      importProblem: '/api/web/honey/withdraw/template/error/export',
    }
  },
  watch: {
    'form.estimatedArrival'(val){
      // console.log('form.estimatedArrival',val,this.form.takePayTax)
      if(val && this.form.takePayTax > 0){
        if(this.form.takePayTax > val){
          this.form.takePayTax = 0
          this.$message.warning('代缴税不能大于预计到账（含税）')
        }else {
          this.form.actualArrival = val - this.form.takePayTax
        }
      }else{
        this.form.actualArrival = val
      }
    },
    'form.takePayTax'(val){
      // console.log('form.takePayTax',val)
      if(val && this.form.estimatedArrival){
        if(val > this.form.estimatedArrival){
          this.form.takePayTax = 0
          this.$message.warning('代缴税不能大于预计到账（含税）')
        }else {
          this.form.actualArrival = this.form.estimatedArrival - val
        }
      }else{
        this.form.actualArrival = 0
      }
    },
    'form.state'(val){
      const takePayTax = this.findObject(this.formOption.column, 'takePayTax');
      const actualArrival = this.findObject(this.formOption.column, 'actualArrival');
      if(val === 2){
        takePayTax.display = true;
        actualArrival.display = true;
      }else{
        takePayTax.display = false;
        actualArrival.display = false;
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 多选
    selectionChange(val){
      // console.log(val)
      this.multipleSelection = val.map(item=>item.id);
      // console.log(this.multipleSelection)
    },
    // 查询
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      this.searchData = {
        name: '',
        payState: '',
        phone: '',
        state: '',
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 导出
    handleExport(){
      // this.$confirm('确定导出数据？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   let url = `/api/web/honey/withdraw/payment/export?name=${this.searchData.name}&payState=${this.searchData.payState}&phone=${this.searchData.phone}&state=${this.searchData.state}`;
      //   let date = new Date();
      //   let time = date.getFullYear() + '' + (date.getMonth() + 1) + '' + date.getDate() + '' + date.getHours() + '' + date.getMinutes() + '' + date.getSeconds();
      //   this.global.exportExcel(url, `提现审核-${time}.xlsx`);
      // })
      window.open(`/api/web/honey/withdraw/export?${qs.stringify(this.searchData)}`,'_self');
    },
    // 文件校验
    beforeUpload(file) {
      // 文件类型
      const isType = file.type === 'application/vnd.ms-excel';
      const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error('上传文件只能是xls/xlsx格式！');
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 2;
      if (!fileLimit) {
        this.$message.error('上传文件大小不超过2M！');
      }
      return fileType && fileLimit;
    },
    // 导入成功
    handleAvatarSuccess(res, file) {
      // console.log(res)
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.onLoad()
      } else {
        this.$message.error(res.msg);
      }
    },

    // 批量审核
    handleAllToExamine(){
      // console.log('批量审核')
      if(this.multipleSelection.length === 0){
        this.$message.warning('请选择需要审核的数据')
      }else{
        this.dialogVisibleToExamineAll = true;
      }
    },
    // 批量打款
    handleAllPayment(){
      if(this.multipleSelection.length === 0){
        this.$message.warning('请选择需要打款的数据');
      }else{
        this.dialogVisiblePayment = true;
        this.formPayment.dialogPaymentType = true;
      }
    },
    // 审核
    handleToExamine(row){
      // console.log('审核',row)
      this.form.id = row.id;
      this.form.integral = row.number;
      this.form.estimatedArrival = row.estimatedArrival;
      // console.log(this.form)
      this.dialogVisible = true;
    },
    // 打款
    handlePayment(row){
      // console.log('打款',row)
      this.dialogVisiblePayment = true;
      this.formPayment = {
        id: row.id,
        dialogPaymentType: false
      }
    },
    // 关闭弹框
    handleClose(){
      this.dialogVisible = false;
      this.resetForm();
    },
    // 重置form
    resetForm(){
      this.form = {
        id: '',
        integral: 0,
        estimatedArrival: 0,
        takePayTax: 0,
        actualArrival: 0,
        state: 2,
        examineDesc: '',
      };
    },
    // 提交
    submit(form,done){
      // console.log('form',form)
      examine({
        id: form.id,
        state: form.state,
        examineDesc: form.examineDesc,
        takePayTax: form.takePayTax,
        estimatedArrival: form.estimatedArrival,
      }).then(res => {
        if(res.code === 200){
          this.$message.success('审核成功');
          this.resetForm();
          this.dialogVisible = false;
          this.onLoad();
        }else{
          this.$message.error(res.msg);
          done();
        }
      }).catch(()=> done())
    },
    // 关闭打款弹框
    handleClosePayment(){
      this.dialogVisiblePayment = false;
    },
    // 打款提交
    handlePaymentSubmit(){
      if(this.formPayment.dialogPaymentType){
        batchPayment({
          idList: this.multipleSelection
        }).then(res => {
          if(res.code === 200){
            this.$message.success('打款成功');
            this.dialogVisiblePayment = false;
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      }else{
        payment({
          id: this.formPayment.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('打款成功');
            this.dialogVisiblePayment = false;
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      }
    },


    // 批量审核
    // 批量审核关闭
    handleCloseToExamineAll(){
      this.dialogVisibleToExamineAll = false;
      this.formToExamineAll = {
        state: 2,
        examineDesc: ''
      }
    },
    // 批量审核提交
    submitToExamineAll(form,done){
      batchExamine({
        idList: this.multipleSelection,
        ...form
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('审核成功');
          this.dialogVisibleToExamineAll = false;
          this.formToExamineAll = {
            state: 2,
            examineDesc: ''
          }
          this.onLoad();
        }else{
          this.$message.error(res.msg);
          done();
        }
      }).catch(()=> done())
    },
    handleImport(){
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e){
      this.importShow = false
      if(e === 1 || e === 2){
        this.onLoad()
      }
    },
  }
}
</script>

<style scoped>
/deep/ .avue-crud .avue-crud__tip{
  display: none;
}
</style>